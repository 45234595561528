import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { BlogLayout, Content } from 'layouts';
import { ArticleHeader, SimilarPostsContainer } from 'components/blog';

const formatDate = (date) => {
  return dayjs(date).format('MMMM D, YYYY');
};

const BlogContainer = styled.div` 
  background-color: ${props => props.theme.colors.gray.lightest}; 
  margin: 0 auto;
  padding: 4rem 6rem 4rem 8rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 4rem;
  @media (max-width: ${props => props.theme.breakpoints.ginormous}) {
    padding: 4rem 3rem 4rem 5rem;
    grid-column-gap: 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xxl}) {
    padding: 4rem 3rem 4rem 4rem;
    grid-column-gap: 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 4rem 2rem;
    grid-column-gap: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    grid-template-columns: auto;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 4rem 1rem;
  }
`;

const ContentContainer = styled.div`  
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  img, video {
    width: 100%;
    box-shadow: ${props => props.theme.shadow.image};
  }
  h3 {
    font-size: 1.3rem;
    font-weight: lighter;
  }
  .badge badge-pill, .badge-secondary {
    margin: 25px 15px 0 0 !important;
    color: ${props => props.theme.colors.black.base};
    background-color: ${props => props.theme.colors.white.white};
    border: 2px solid ${props => props.theme.colors.blue.base};
    border-radius: 3px;
  }
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 2rem 1rem;
    max-width: 80%;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    max-width: 90%;
    img, video {
      max-width: 100%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
    margin: 0 auto;
    padding: 0;
    }
  }
`;

const SuggestionBar = styled.div`
  background: ${props => props.theme.colors.white.light};
`;

const Post = ({ data, location }) => {
  const {
    category,
    coverImage,
    content,
    markdownContent,
    excerpt,
    date,
    readtime,
    author,
    title,
    subtitle,
    tags,
    slug,
    seoTitle,
    seoDescription,
    seoImage
  } = data.gcms.post;

  const image = coverImage.node.childImageSharp.fluid;
  const postSeoTitle = seoTitle || title;
  const postSeoDescription = seoDescription || excerpt;
  const postSeoImage = seoImage ? seoImage.url : coverImage.url;

  return (
    <BlogLayout
      location={location}
      seoTitle={postSeoTitle}
      seoDescription={postSeoDescription}
      seoImage={postSeoImage}
      article={markdownContent || content.text}
      pathname={slug}
    >
      <BlogContainer>
        <ContentContainer>
          <div>
            <ArticleHeader path={slug} title={title} subtitle={subtitle || ''} author={author.name} authorImage={author.picture.url} date={formatDate(date)} readtime={readtime + ' min read'} cover={image} tags={tags} />
            <Content richTextContent={content && content.html} markdownContent={markdownContent} />
            {tags &&
          tags.map(tag => {
            return (
              <a key={typeof tag === 'string' ? tag : tag.name} href={`https://stackery.io/tags/${tag.slug}`} className='badge badge-pill badge-secondary'>{typeof tag === 'string' ? tag : tag.name}</a>
            );
          })}
          </div>
        </ContentContainer>
      </BlogContainer>
      <SuggestionBar>
        <SimilarPostsContainer category={category.slug} tags={tags.map(tag => tag.slug)} currentPostSlug={slug} />
      </SuggestionBar>
    </BlogLayout>
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object
  }).isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export const query = graphql`
  query($slug: String!) {
    gcms {
      post(where: {slug: $slug}) {
        id
        date
        readtime
        excerpt
        title
        subtitle
        category {
          name
          slug
        }
        tags {
          name
          slug
        }
        author {
          name
          picture {
            url(transformation: {image: {resize: {}}, document: {output: {format: jpg}}})
          }
        }
        content {
          html
        }
        markdownContent
        coverImage {
          url
          node {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        slug
        seoTitle
        seoDescription
        seoImage {
          url
        }
      }
    }
  }
`;
